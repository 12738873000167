<template>
  <div class="main">
    <BigTitleBner title="PRODUCT" :url="`${this.$imgUrl}img_p_1.png`" />
    <div class="main-con">
      <div class="containerView view_flex">
        <div class="con-cate">
          <cateView
            :list="cateList"
            @onchange="onchange_cate"
            @initBack="initBack"
          />
        </div>
        <div class="con-goodsBox">
          <goodsListView
            ref="goodsList"
            :cateId="cateId"
            :cateName="cateName"
            :model="model"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BigTitleBner from "@/components/BigTitleBner";
import cateView from "./components/cate/index";
import goodsListView from "./components/goodsList/index";
import { handleTree } from "@/utils/util";
import { mapState } from "vuex";
export default {
  name: "product",
  data() {
    return {
      cateId: 0,
      cateName: "",
      model: 1, //1:普通请求，2：热门收藏，3：直接请求详情, 4:首页轮播
    };
  },
  mounted() {
    let params = this.$route.params;
    if (Object.keys(params).length) {
      let { id, model } = params;
      this.cateName = this.$t("productrText.text4");
      this.cateId = id;
      this.model = id ? 3 : 2;
      this.model = model || this.model;
      setTimeout(() => {
        this.model != 4 && this.$refs.goodsList.getlist();
      }, 100);
    }
  },
  computed: {
    ...mapState({
      cateList: (state) => {
        let tree = handleTree(state.config.CATELIST, "id", "pid");
        return tree;
      },
    }),
  },
  methods: {
    init() {
      return new Promise((resolve) => {
        this.$refs.goodsList.getlist();
        resolve(true);
      });
    },
    initBack(e) {
      if (this.model == 1) {
        let { id, name } = e;
        this.cateId = id;
        this.cateName = name;
      }
    },
    onchange_cate(e) {
      let { id, name } = e;
      this.cateId = id;
      this.cateName = name;
      this.model = 1;
    },
    setCateName(val) {
      this.cateName = val;
    },
  },
  components: {
    BigTitleBner,
    cateView,
    goodsListView,
  },
};
</script>
<style lang="scss" scoped>
.con-cate {
  width: 218px;
}

.con-goodsBox {
  flex: 1;
  width: 0;
  margin-top: 58px;
}

.main-con {
  padding-top: 100px;
  padding-bottom: 100px;
}
</style>
