<template>
  <div class="main">
    <div class="main-title view_flex_c">
      <h3 class="size_18">{{ cateName }}&nbsp;&nbsp;</h3>
      <div class="view_flex_c" v-if="loading">
        <v-progress-circular
          indeterminate
          size="18"
          width="2"
          color="#888"
        ></v-progress-circular>
      </div>
    </div>
    <div class="main-con">
      <template v-if="!detailShow">
        <div class="con-list">
          <template v-for="(item, index) in list">
            <div class="view_flex_cc" :key="index">
              <div class="goods-card scale" @click="getDetail(item.productId)">
                <div class="imgBox">
                  <v-img
                    height="265"
                    :src="
                      $lineUrl + item.mainImage + '?imageView2/2/w/600/h/600'
                    "
                    :lazy-src="
                      $lineUrl + item.mainImage + '?imageView2/2/w/265/h/265'
                    "
                  ></v-img>
                </div>
                <div class="mask view_flex_cc">
                  <div class="text_center">
                    <div class="mask-name text_rowMore">
                      {{ item.productName }}
                    </div>
                    <div class="mask-qrcode">
                      <img
                        :src="
                          $lineUrl +
                          item.miniProductQrcode +
                          '?imageView2/2/w/265/h/265'
                        "
                      />
                    </div>
                    <div class="mask-tips text_rowMore">
                      {{ $t("productrText.text5") }}
                    </div>
                    <div class="mask-check text_rowMore view_flex_cc">
                      <div>{{ $t("productrText.text6") }}</div>
                      <v-icon color="#fff" dense style="margin-top: 4px">
                        mdi-chevron-right
                      </v-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-if="!list.length" style="padding-left: 60px">
          {{ $t("productrText.text3") }}
        </div>
        <div v-else class="main-footer view_flex_c size_14">
          <div>
            <v-pagination
              v-model="query.page"
              color="#121229"
              :length="length"
              :total-visible="6"
              @input="getlist"
            ></v-pagination>
          </div>
          <div>到第</div>
          <div class="inputClass view_flex_cc">
            <input type="text" :value="pageNumber" id="pageNumber" />
          </div>
          <div>页</div>
          <div>
            <v-btn elevation="2" class="btn-confirm" @click="confirm"
              >确定</v-btn
            >
          </div>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;共{{ length }}页</div>
        </div>
      </template>
      <template v-else>
        <detail :detail="detail" @change="back" />
      </template>
    </div>
  </div>
</template>
<script>
import detail from "../detail";
import {
  getproductList,
  getproductDetail,
  getproductListbyHot,
} from "@/api/product.js";
export default {
  name: "imgBoxList",
  props: {
    cateId: {
      type: Number,
      default: 0,
    },
    cateName: {
      type: String,
      default: "分类",
    },
    model: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      list: [],
      query: {
        id: 0,
        page: 1,
        size: 12,
      },
      total: 0,
      detailShow: false,
      length: 1,
      detail: {},
      pageNumber: 1,
      loading: false,
      staging: "",
    };
  },
  components: {
    detail,
  },
  watch: {
    cateId(id) {
      if (this.model != 3 && this.model != 4) {
        this.query = {
          id,
          page: 1,
        };
        this.detailShow = false;
        this.getlist();
      } else {
        //获取热门商品详情
        this.detailShow = true;
        this.getDetail(id);
      }
    },
  },
  methods: {
    getlist() {
      this.loading = true;
      window.scrollTo({
        top: 800,
        behavior: "smooth",
      });
      let data = this.query;
      let fun =
        this.model == 1 || this.model == 4
          ? getproductList
          : getproductListbyHot;
      fun(data).then((res) => {
        let { rows, total } = res;
        this.list = rows;
        this.total = total;
        this.length = Math.ceil(total / 12);
        this.loading = false;
        this.staging = "";
      });
    },
    getDetail(id) {
      getproductDetail(id).then((res) => {
        this.detail = res.data;
        this.detailShow = true;
        if (this.model == 4) {
          let { productCategoryName: name, productCategoryId: id } = res.data;
          this.$parent.setCateName(name);
          let data = {
            id,
            name,
          };
          this.staging = data;
        }
        window.scrollTo({
          top: 800,
          behavior: "smooth",
        });
      });
    },
    confirm() {
      let pageNumber = document.getElementById("pageNumber").value;
      pageNumber = pageNumber.replace(/[^0-9]/g, "");
      if (!pageNumber || pageNumber > this.length) {
        pageNumber = 1;
      }
      pageNumber = Number(pageNumber);
      this.pageNumber = pageNumber;
      this.query.page = pageNumber;
      this.getlist();
    },
    back() {
      window.scrollTo({
        top: 800,
        behavior: "smooth",
      });
      if (this.staging) {
        this.$parent.onchange_cate(this.staging);
        this.staging = "";
      }
      this.detailShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.con-list {
  font-size: 0;
  padding: 0 50px;
  box-sizing: border-box;
}

.main-title {
  margin-bottom: 26px;
  padding-left: 60px;
  box-sizing: border-box;
}

.con-list > div {
  width: calc(100% / 3);
  display: inline-flex;
}

.goods-card {
  width: 265px;
  height: 265px;
  background: #eee;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  > .imgBox,
  .mask {
    width: 100%;
    height: 100%;
    transition: all 0.6s;
  }
  .mask {
    background: rgba($color: #000, $alpha: 0.6);
    transform: translateY(0px);
    .mask-qrcode {
      width: 105px;
      height: 105px;
      background: #fff;
      border-radius: 50%;
      margin: auto;
      margin-top: 16px;
      margin-bottom: 10px;
      transition: all 0.6s;
      overflow: hidden;
    }
    .mask-name {
      font-size: 18px;
      font-weight: 600;
      margin: 0 30px;
      color: #fff;
    }
    .mask-tips {
      font-size: 14px;
      margin: 0 30px;
      color: #fff;
    }
    .mask-check {
      display: flex !important;
      margin-top: 5px;
      font-size: 12px;
      color: #fff;
    }
  }
}

.goods-card:hover {
  .imgBox {
    filter: blur(3px);
  }
  .mask {
    transform: translateY(-265px);
  }
}

.main-footer {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  margin-top: 30px;
  /deep/ .v-pagination__navigation {
    box-shadow: none !important;
  }
  /deep/ .v-pagination__item {
    box-shadow: none !important;
  }
  .inputClass {
    width: 50px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    opacity: 1;
    border-radius: 1px;
    margin: 0 10px;
    > input {
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
  .btn-confirm {
    width: 50px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    opacity: 1;
    border-radius: 1px;
    box-shadow: none !important;
    margin-left: 10px;
  }
}
</style>
