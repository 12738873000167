<template>
  <div class="detail">
    <div class="detail-name text_bold size_18">
      {{ detail.productName }}
    </div>
    <div class="detail-con" v-if="detail.detailImage">
      <template v-for="(item, index) in detail.detailImage.split(',')">
        <div class="con-img" :key="index">
          <v-img
            :src="$lineUrl + item + '?imageView2/2/w/800/h/800'"
            :lazy-src="$lineUrl + item + '?imageView2/2/w/265/h/265'"
          />
        </div>
      </template>
    </div>
    <div class="detail-footer text_center">
      <v-btn class="btn-back" color="#13131D" @click="back">
        {{ $t("productrText.text2") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "detail",
  props: {
    detail: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    back() {
      this.$emit("change");
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  margin-left: 60px;
}

.con-img {
  width: 706px;
}

.detail-name {
  margin-bottom: 20px;
}

.btn-back {
  width: 340px !important;
  height: 60px !important;
  background: #13131d;
  opacity: 1;
  border-radius: 4px;
  font-size: 22px;
  color: #fff;
}

.detail-footer {
  margin-top: 80px;
}
</style>
