<template>
  <div class="main">
    <div class="cate-title">
      <h3 class="size_18">{{ $t("productrText.text1") }}</h3>
    </div>
    <div class="cate-con">
      <v-list>
        <v-list-group
          v-for="(item, index) in list"
          :key="index"
          color="#000"
          :append-icon="item.children ? '$expand' : ''"
          @click="get(item)"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </template>

          <v-list-group
            :value="true"
            no-action
            sub-group
            v-for="(itemC, indexC) in item.children"
            :key="indexC"
            color="#000"
            :append-icon="itemC.children ? '$expand' : ''"
            @click="get(itemC)"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ itemC.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(itemS, indexs) in itemC.children"
              :key="indexs"
              @click="get(itemS)"
            >
              <v-list-item-title>{{ itemS.name }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>
    </div>
  </div>
</template>
<script>
export default {
  name: "cate",
  props: {
    list: {
      type: Array,
      default: function () {
        return null;
      },
    },
  },
  mounted() {
    let list = this.list;
    let { id, name } = list[0];
    let data = {
      id,
      name,
    };
    this.$emit("initBack", data);
  },
  watch: {
    list(arr) {
      let { id, name } = arr[0];
      let data = {
        id,
        name,
      };
      this.$emit("onchange", data);
    },
  },
  data() {
    return {};
  },
  methods: {
    get(data) {
      this.$emit("onchange", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.cate-con {
  height: 1000px;
  overflow-y: scroll;
}

.cate-title {
  padding-left: 16px;
  margin-bottom: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0;
  background-color: #fff;
}

.pointer {
  cursor: pointer;
}
</style>
